import styled from '@emotion/styled';
import svg from '../../public/images/heart.svg';

const Icon = styled(svg)``;

Icon.whyDidYouRender = true;

Icon.whyDidYouRender = true;

export default Icon;
